const LocalStorage = {
  get: function (item) {
    return typeof window !== 'undefined' && window.localStorage ? localStorage.getItem(item) : '';
  },
  set: function (key, value) {
    return typeof window !== 'undefined' && window.localStorage ? localStorage.setItem(key, value) : '';
  },
  removeItem: function(key) {
    return typeof window !== 'undefined' && window.localStorage ? localStorage.removeItem(key) : '';
  }
}

export default LocalStorage;